<template>
    <div class="production">
        <div class="container">
            
            <div class="box1" style="display:flex;margin: 100px auto;justify-content: space-between;width: 950px;">
                <div class="box1_child">
                    <img style="width: 230px;height:300px;" src="../../assets/home_index/bg.jpeg" alt="">
                    <h2>医共体大健康平台</h2>
                    <!-- <p>认知症等健康筛查</p> -->
                </div>
                <div class="box1_child">
                    <img style="width: 230px;height:300px;" src="../../assets/home_index/bg.jpeg" alt="">
                    <h2>院外上门延护系统</h2>
                    <!-- <p>患者出院后到延续服务</p> -->
                </div>
                <div class="box1_child">
                    <img style="width: 230px;height:300px;" src="../../assets/home_index/bg.jpeg" alt="">
                    <h2>辅具租售平台</h2>
                    <!-- <p>康复评估、辅具的智能推荐、租售</p> -->
                </div>
            </div>
            <div class="box2">

            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.production{
    width: 1240px;
    background-color: #fff;
    padding-bottom: 20px;
    margin: auto;
    overflow: hidden;
    .contanier{
        padding: 50px;
        .box1{
            height: 400px;
            background-color: #fff;
            .box1_child{
                width: 230px;
                height: 320px;
                

            }
        }
    }
}
</style>